import React from "react";

const ResourcesUpper = () => {
    return (
        <div className="upper-container">
            <h1>ResourcesUpper</h1>
        </div>
    )
}

export default ResourcesUpper