import React from "react";

const MailingsUpperUpper = () => {
    return (
        <div className="upper-container">
            <h1>MailingsUpper</h1>
        </div>
    )
}

export default MailingsUpperUpper