import React from "react";

const CatalogueRequests = () => {
    return (
        <div className="lower-container">
            <h1>CatalogueRequests</h1>
        </div>
    )
}

export default CatalogueRequests