import React from "react";

const HomeLower = () => {
    return (
        <div className="lower-container">
            <h2 className="lower-header fade-in">Welcome</h2>
        </div>
    )
}

export default HomeLower